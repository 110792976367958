@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-comment {
  margin-bottom: 2rem;
  flex: 1 0 50%;
  max-width: ~"calc(50% - 1rem)";
  justify-content: space-between;

  @media only screen and (max-width: 550px) {
    flex: 1 0 100%;
    max-width: ~"calc(100% - 2rem)";
  }

  img {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    vertical-align: middle;
    border-radius: 100%;
  }

  .me-comment-title {
    display: inline-block;
    width: ~"calc(100% - 50px - 1rem)";
    vertical-align: middle;
    line-height: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .me-comment-title-first-line {
    font-weight: 600;
  }

  .me-comment-title-second-line {
    color: #858585;
  }

  .me-comment-text {
    position: relative;
    background: #ebebeb;
    padding: 1rem;
    border-radius: 3px;
    margin-top: 1.25rem;

    &:before {
      content: '';
      position: absolute;
      top: -0.65rem;
      left: 0.9rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.7rem 0.7rem 0.7rem;
      border-color: transparent transparent #ebebeb transparent;
    }
  }
}
