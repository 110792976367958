@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-footer {
  border-top: 1px solid rgba(0,0,0,0.25);
  margin-top: 7rem;
  padding: 2rem 0;
  z-index: 1;
  background-color: white;

  .me-footer--grid {
    display: grid;
  }

  @media only screen and (min-width: 992px) {

    .text-list { grid-column: ~"1/3"; grid-row: 1; margin-bottom: 3rem; }
    .logo      { grid-column: 1;      grid-row: 2; margin-bottom: 0.5rem; }
    .icon-list { grid-column: 2;      grid-row: 2; text-align: right; margin-right: -5px; }
    p          { grid-column: ~"1/3"; grid-row: 3; }
  }

  @media only screen and (max-width: 991px) {
    .me-footer--grid {
      text-align: center;
    }

    .text-list { grid-column: ~"1"; grid-row: 1; flex-direction: column; margin-bottom: 3rem; }
    .logo      { grid-column: ~"1"; grid-row: 2; place-self: center; margin-bottom: 1rem; }
    .icon-list { grid-column: ~"1"; grid-row: 4; }
    p          { grid-column: ~"1"; grid-row: 3; margin-bottom: 2rem; }

    .text-list li {
      margin-bottom: 0.5rem;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .text-list {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .icon-list {
    padding: 0;

    a {
      margin: 0 1rem;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    a:last-child,
    a:last-child i {
      @media only screen and (min-width: 992px) {
        margin-right: 0;
      }
    }
    i {
      font-size: 1.75rem;
    }
    a.facebook {
      color: @facebookColor;
    }
    a.instagram {
      color: #da3074;
    }
  }
}

.footer-counterpart {
  margin-bottom: ~"calc(-7rem - 1px)";
  @media only screen and (max-width: 767px) {
    margin-bottom: ~"calc(-6rem - 1px)";
  }
}
