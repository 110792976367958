@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-fullwidth-banner {
  padding: 2rem 1rem;
  background: @darkWhite;
  margin-top: 1rem;
  width: 100%;

  @media only screen and (min-width: @tabletBreakpoint) {
    padding: 2rem 10rem;
    text-align: right;
    width: calc(100% + 18rem);
    margin-left: -9rem;
    margin-right: -9rem;
  }

  a {
    color: black;
  }

  i {
    color: @lightBlack;
    position: relative;
    top: 1px;
  }
}
