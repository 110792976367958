@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

body.has-cookies-banner {
  @media only screen and (max-width: @largestMobileScreen) {
    @height: 145px;

    .mobile-sticky-donate-bar {
      margin-bottom: @height;
    }

    #cookies-eu-banner {
      height: @height;
    }
    #me-application {
      padding-bottom: 8rem;
    }
  }
}
