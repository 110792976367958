/*-------------------
    Body overrides
--------------------*/
body { letter-spacing: 0.005rem; background: #ebebeb; }
/* SemanticUI has '@fontSmoothing: antialiased;' but applies it to the not supporte key 'font-smoothing' */
body { -webkit-font-smoothing: antialiased; }

a { cursor: pointer; }

/*-------------------
    Spacing utils
--------------------*/
@miniSpacingSize        : (5 / 14);
@smallSpacingSize       : (10 / 14);
@mediumSpacingSize      : (15 / 14);
@largeSpacingSize       : (28 / 14);
@bigSpacingSize         : (42 / 14);

@miniSpacing     : unit( round(@miniSpacingSize * @emSize) / @emSize, rem);
@smallSpacing    : unit( round(@smallSpacingSize * @emSize) / @emSize, rem);
@mediumSpacing   : unit( round(@mediumSpacingSize * @emSize) / @emSize, rem);
@largeSpacing    : unit( round(@largeSpacingSize * @emSize) / @emSize, rem);
@bigSpacing      : unit( round(@bigSpacingSize * @emSize) / @emSize, rem);

html .mb-mini { margin-bottom: @miniSpacing; }
html .mb-small { margin-bottom: @smallSpacing; }
html .mb-medium { margin-bottom: @mediumSpacing; }
html .mb-large { margin-bottom: @largeSpacing; }
html .mb-big { margin-bottom: @bigSpacing; }

html .mt-mini { margin-top: @miniSpacing; }
html .mt-small { margin-top: @smallSpacing; }
html .mt-medium { margin-top: @mediumSpacing; }
html .mt-large { margin-top: @largeSpacing; }
html .mt-big { margin-top: @bigSpacing; }

html .ml-mini { margin-left: @miniSpacing; }
html .ml-small { margin-left: @smallSpacing; }
html .ml-medium { margin-left: @mediumSpacing; }
html .ml-large { margin-left: @largeSpacing; }
html .ml-big { margin-left: @bigSpacing; }

html .ui.mb-mini { margin-bottom: @miniSpacing; }
html .ui.mb-small { margin-bottom: @smallSpacing; }
html .ui.mb-medium { margin-bottom: @mediumSpacing; }
html .ui.mb-large { margin-bottom: @largeSpacing; }
html .ui.mb-big { margin-bottom: @bigSpacing; }

html .ui.mt-mini { margin-top: @miniSpacing; }
html .ui.mt-small { margin-top: @smallSpacing; }
html .ui.mt-medium { margin-top: @mediumSpacing; }
html .ui.mt-large { margin-top: @largeSpacing; }
html .ui.mt-big { margin-top: @bigSpacing; }

html .ui.ml-mini { margin-left: @miniSpacing; }
html .ui.ml-small { margin-left: @smallSpacing; }
html .ui.ml-medium { margin-left: @mediumSpacing; }
html .ui.ml-large { margin-left: @largeSpacing; }
html .ui.ml-big { margin-left: @bigSpacing; }


/*-------------------
  Links and Buttons
--------------------*/

@microButtonSize       : (8 / 14);
@microButton          : unit( round(@microButtonSize * @emSize) / @emSize, rem);

html .ui.micro.button {
  font-size: @microButton;
}

h1, h2, h3, h4, h5 {
  word-wrap: break-word;
}

/*-------------------
  IE Hacks :|
--------------------*/
input::-ms-clear {
  display: none;
}

/*-------------------
  Dropdown
--------------------*/

.ui.dropdown:not(.button) > .default.text {
  color: @black !important;
}

.ui.dropdown.disabled {
  opacity: 0.5 !important
}

/*-------------------
  List
--------------------*/

.ui.list .list > .item > i.icon, .ui.list > .item > i.icon {
  padding-right: 0 !important
}

/*-------------------
  More utilities
--------------------*/

.p-0 {
  padding: 0 !important;
}
.line-height-inherit {
  line-height: inherit !important;
}
