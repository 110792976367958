/*

███████╗███████╗███╗   ███╗ █████╗ ███╗   ██╗████████╗██╗ ██████╗    ██╗   ██╗██╗
██╔════╝██╔════╝████╗ ████║██╔══██╗████╗  ██║╚══██╔══╝██║██╔════╝    ██║   ██║██║
███████╗█████╗  ██╔████╔██║███████║██╔██╗ ██║   ██║   ██║██║         ██║   ██║██║
╚════██║██╔══╝  ██║╚██╔╝██║██╔══██║██║╚██╗██║   ██║   ██║██║         ██║   ██║██║
███████║███████╗██║ ╚═╝ ██║██║  ██║██║ ╚████║   ██║   ██║╚██████╗    ╚██████╔╝██║
╚══════╝╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚═╝ ╚═════╝     ╚═════╝ ╚═╝

  Import this file into your LESS project to use Semantic UI without build tools
*/

/* Global */
& {
  @import '~semantic-ui-less/definitions/globals/reset';
}
& {
  @import '~semantic-ui-less/definitions/globals/site';
}

/* Elements */
& {
  @import '~semantic-ui-less/definitions/elements/button';
}
& {
  @import '~semantic-ui-less/definitions/elements/container';
}
& {
  @import '~semantic-ui-less/definitions/elements/divider';
}
// & { @import "~semantic-ui-less/definitions/elements/flag"; }
& {
  @import '~semantic-ui-less/definitions/elements/header';
}
& {
  @import '~semantic-ui-less/definitions/elements/icon';
}
& {
  @import '~semantic-ui-less/definitions/elements/image';
}
& {
  @import '~semantic-ui-less/definitions/elements/input';
}
& {
  @import '~semantic-ui-less/definitions/elements/label';
}
& {
  @import '~semantic-ui-less/definitions/elements/list';
}
& {
  @import '~semantic-ui-less/definitions/elements/loader';
}
& {
  @import '~semantic-ui-less/definitions/elements/placeholder';
}
// & { @import "~semantic-ui-less/definitions/elements/rail"; }
& {
  @import '~semantic-ui-less/definitions/elements/reveal';
}
& {
  @import '~semantic-ui-less/definitions/elements/segment';
}
& {
  @import '~semantic-ui-less/definitions/elements/step';
}

/* Collections */
// & { @import "~semantic-ui-less/definitions/collections/breadcrumb"; }
& {
  @import '~semantic-ui-less/definitions/collections/form';
}
& {
  @import '~semantic-ui-less/definitions/collections/grid';
}
& {
  @import '~semantic-ui-less/definitions/collections/menu';
}
& {
  @import '~semantic-ui-less/definitions/collections/message';
}
& {
  @import '~semantic-ui-less/definitions/collections/table';
}

/* Views */
// & { @import "~semantic-ui-less/definitions/views/ad"; }
& {
  @import '~semantic-ui-less/definitions/views/card';
}
& {
  @import '~semantic-ui-less/definitions/views/comment';
}
// & { @import "~semantic-ui-less/definitions/views/feed"; }
& {
  @import '~semantic-ui-less/definitions/views/item';
}
// & { @import "~semantic-ui-less/definitions/views/statistic"; }

/* Modules */
& {
  @import '~semantic-ui-less/definitions/modules/accordion';
}
& {
  @import '~semantic-ui-less/definitions/modules/checkbox';
}
& {
  @import '~semantic-ui-less/definitions/modules/dimmer';
}
& {
  @import '~semantic-ui-less/definitions/modules/dropdown';
}
// & { @import "~semantic-ui-less/definitions/modules/embed"; }
& {
  @import '~semantic-ui-less/definitions/modules/modal';
}
// & { @import "~semantic-ui-less/definitions/modules/nag"; }
& {
  @import '~semantic-ui-less/definitions/modules/popup';
}
& {
  @import '~semantic-ui-less/definitions/modules/progress';
}
// & { @import "~semantic-ui-less/definitions/modules/rating"; }
// & { @import "~semantic-ui-less/definitions/modules/search"; }
// & { @import "~semantic-ui-less/definitions/modules/shape"; }
// & { @import "~semantic-ui-less/definitions/modules/sidebar"; }
// & { @import "~semantic-ui-less/definitions/modules/sticky"; }
& {
  @import '~semantic-ui-less/definitions/modules/tab';
}
& {
  @import '~semantic-ui-less/definitions/modules/transition';
}

& {
  @import 'me/stylesheets/cookie_banner';
}
& {
  @import 'me/stylesheets/campaign_card';
}
& {
  @import 'me/stylesheets/glide';
}
& {
  @import 'me/stylesheets/header';
}
& {
  @import 'me/stylesheets/helpers';
}
& {
  @import 'me/stylesheets/footer';
}
& {
  @import 'me/stylesheets/fill_viewport_height';
}
& {
  @import 'me/stylesheets/category';
}
& {
  @import 'me/stylesheets/campaign_progress';
}
& {
  @import 'me/stylesheets/mobile_sticky_donate_bar';
}
& {
  @import 'me/stylesheets/postdonation_progress';
}
& {
  @import 'me/stylesheets/pricing';
}
& {
  @import 'me/stylesheets/fullwidth_banner';
}
& {
  @import 'me/stylesheets/comment';
}
& {
  @import 'me/stylesheets/rich_text_editor';
}
& {
  @import 'me/stylesheets/rich_text_content';
}
& {
  @import 'me/stylesheets/single_column_layout';
}

.ui.header > .ui.label {
  height: 1.375rem;
  min-width: 1.375rem;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 0.875rem;
}
