@buttonOverrideColors: red, orange, yellow, olive, green, teal, blue, violet, purple, pink, brown, grey, black;

.-(@i: length(@buttonOverrideColors)) when (@i > 0) {
  @name: extract(@buttonOverrideColors, @i);

  .ui.@{name}.button {
    box-shadow: @boxShadow;
  }

  .ui.@{name}.buttons .button:hover,
  .ui.@{name}.button:hover {
    box-shadow: none;
  }

  .ui.basic.@{name}.buttons .button,
  .ui.basic.@{name}.button {
    box-shadow: @basicBoxShadow, 0px 0px 0px @basicBorderSize @@name inset !important;
  }

  .ui.basic.@{name}.buttons .button:hover,
  .ui.basic.@{name}.button:hover {
    background: @white !important;
  }

  .ui.basic.@{name}.buttons .button:focus,
  .ui.basic.@{name}.button:focus {
    background: @white !important;
  }

  .ui.basic.@{name}.buttons .active.button,
  .ui.basic.@{name}.active.button {
    background: @white !important;
  }

  .-((@i - 1));
} .-;

.ui.button:not(.primary):not(.blue):not(.green) {
  border: 1px solid @legiblePrimaryColor;
  color: @legiblePrimaryColor;
}

/* Primary */
.ui.primary.button {
  box-shadow: 0px 3px 1px @buttonPrimaryShadowColor; // flavored
}

.ui.primary.button:focus {
  background: @primaryColor;
  box-shadow: inset 0px 0px 0px 5px @buttonPrimaryFocusBorderColor; // flavored
}

.ui.primary.button:hover {
  background: @buttonPrimaryHoveredColor; // flavored
  color: @buttonPrimaryHoveredTextColor; // flavored
  box-shadow: 0px 3px 1px @buttonPrimaryShadowColor; // flavored
}

.ui.primary.button.active,
.ui.primary.button:active {
  background: @buttonPrimaryActiveColor; // flavored
  box-shadow: inset 0px 3px 1px @buttonPrimaryShadowColor; // flavored
  border: none;
}

/* Primary basic */

.ui.basic.primary.buttons .button,
.ui.basic.primary.button {
  background: @buttonPrimaryBasicBackgroundColor !important;
  border: 2px solid @buttonPrimaryBasicBorderColor !important;
  color: @buttonPrimaryBasicTextColor !important;
  box-shadow: none !important;
  font-weight: 700;
}

.ui.basic.primary.buttons .button:hover,
.ui.basic.primary.button:hover {
  background: @buttonPrimaryBasicActiveBackgroundColor !important;
  color: @buttonPrimaryBasicActiveTextColor !important;
  box-shadow: none !important;
}

.ui.basic.primary.buttons .button:focus,
.ui.basic.primary.button:focus {
  background: @buttonPrimaryBasicBackgroundColor !important;
  color: @buttonPrimaryBasicTextColor !important;
  box-shadow: inset 0 0 0 2px @buttonPrimaryBasicBorderColor !important;
}

.ui.basic.primary.button.active,
.ui.basic.primary.button:active {
  background: @buttonPrimaryBasicActiveBackgroundColor !important;
  color: @buttonPrimaryBasicActiveTextColor !important;
  box-shadow: inset 0 3px 1px @buttonPrimaryBasicShadowColor !important;
}

.ui.basic.primary.buttons .active.button,
.ui.basic.primary.active.button {
  background: @white !important;
}

/* Secondary */
.ui.secondary.button {
  box-shadow: @boxShadow;
}

.ui.secondary.buttons .button:hover,
.ui.secondary.button:hover {
  box-shadow: none;
}

.ui.basic.secondary.buttons .button,
.ui.basic.secondary.button {
  box-shadow: @basicBoxShadow, 0px 0px 0px @basicBorderSize @secondaryColor inset !important;
}

.ui.basic.secondary.buttons .button:hover,
.ui.basic.secondary.button:hover {
  background: @white !important;
}

.ui.basic.secondary.buttons .button:focus,
.ui.basic.secondary.button:focus {
  background: @white !important;
}

.ui.basic.secondary.buttons .active.button,
.ui.basic.secondary.active.button {
  background: @white !important;
}

/* Disabled */
/* copied from https://github.com/Semantic-Org/Semantic-UI/blob/deb275d/src/definitions/elements/button.less#L200 */

.ui.buttons .disabled.button,
.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  opacity: 1 !important;
  background: @buttonDisabledBackgroundColor; // flavored
  color: @buttonDisabledTextColor;
}

.ui.buttons .disabled.button.basic,
.ui.disabled.button.basic,
.ui.button.basic:disabled,
.ui.disabled.basic.button:hover,
.ui.disabled.basic.active.button {
  background: @buttonDisabledBasicBackgroundColor !important;
  border-color: @buttonDisabledTextColor !important;
  color: @buttonDisabledTextColor !important;
  box-shadow: none !important;
}

/* Negative */

.ui.negative.button {
  background: @buttonNegativeBackgroundColor;
  color: @buttonNegativeTextColor;
  box-shadow: 0 3px 1px @buttonNegativeShadowColor;
}

.ui.negative.button:hover {
  background: @buttonNegativeHoveredColor;
  box-shadow: 0 3px 1px @buttonNegativeShadowColor;
}

.ui.negative.button:focus {
  background: @buttonNegativeBackgroundColor;
  box-shadow: inset 0px 0px 0px 5px @buttonNegativeBorderColor;
}

.ui.negative.active.button,
.ui.negative.button:active {
  background: @buttonNegativeActiveBackgroundColor;
  box-shadow: inset 0 3px 1px @buttonNegativeShadowColor;
}

/* All buttons */

.ui.button {
  -webkit-font-smoothing: antialiased;
}
