.ql-toolbar {
  border-radius: 2px 2px 0 0;
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.ql-container {
  font-family: inherit;
  font-size: inherit;
  background: white;
  border-radius: 0 0 2px 2px;

  .ql-editor {
    min-height: 10em;
    position: relative;
  }

  .ql-tooltip {
    width: 100%;
    top: auto !important; /* Overrule inline styles set by Quill. */
    left: 0 !important;
    bottom: 0 !important;
    transform: none;
  }

  .ql-tooltip:not(.ql-hidden) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .ql-tooltip input[type='text'] {
    flex-grow: 3;
    flex-basis: 50%;
  }

  .ql-preview {
    display: block;
    flex-basis: 100%;
  }

  .ql-tooltip::before {
    content: 'Link:';
    margin-right: 0;
  }

  .ql-tooltip a.ql-action::after {
    content: 'Bearbeiten';
    margin-left: 0;
  }

  .ql-tooltip a.ql-remove::before {
    content: 'Löschen';
    margin-left: 0;
  }

  .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Speichern';
  }

  .ql-tooltip[data-mode='link']::before {
    content: 'Link:';
  }

  .ql-tooltip[data-mode='formula']::before {
    content: 'Formel:';
  }

  .ql-tooltip[data-mode='video']::before {
    content: 'Video-URL:';
  }
}

.ql-picker {
  &.ql-header {
    .ql-picker-item::before {
      content: 'Normal';
    }

    .ql-picker-item[data-value='1']::before {
      content: 'Überschrift 1';
    }

    .ql-picker-item[data-value='2']::before {
      content: 'Überschrift 2';
    }

    .ql-picker-item[data-value='3']::before {
      content: 'Überschrift 3';
    }

    .ql-picker-item[data-value='4']::before {
      content: 'Überschrift 4';
    }

    .ql-picker-item[data-value='5']::before {
      content: 'Überschrift 5';
    }

    .ql-picker-item[data-value='6']::before {
      content: 'Überschrift 6';
    }
  }

  &.ql-size {
    .ql-picker-item::before {
      content: 'Normal';
    }

    .ql-picker-item[data-value='small']::before {
      content: 'Klein';
    }

    .ql-picker-item[data-value='large']::before {
      content: 'Groß';
    }

    .ql-picker-item[data-value='huge']::before {
      content: 'Riesig';
    }
  }
}
