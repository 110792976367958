#me-application {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: white;
  box-shadow: 0 0 1rem 0rem rgba(0,0,0,0.2);
  max-width: 1440px;
  margin: 0 auto;

  .me-fill-viewport-height {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
