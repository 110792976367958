@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.hide-on-mobile {
  @media only screen and (max-width: calc(@tabletBreakpoint - 1px)) {
    display: none !important;
  }
}

.show-on-mobile {
  @media only screen and (min-width: @tabletBreakpoint) {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .ui.container.zero-margin-on-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.cta-box {
  background: @ctaBoxBackgroundColor !important; // flavored
  color: @ctaBoxTextColor !important; // flavored
  .header {
    color: @ctaBoxTextColor !important; // flavored
  }
}
