@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.glide {
  margin-left: ~"calc(-1rem - 2px)";
  width: ~"calc(100% + 2rem + 4px)";

  .glide__bullets {
    margin-top: -10px;
    text-align: center;
  }

  .glide__bullet {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 100%;
    border: 2px solid transparent;
    cursor: pointer;
    margin: 0 0.25em;
    background-color: @midWhite;
  }

  .glide__bullet--active {
    background-color: @secondaryColor;
  }

  .glide__slides {
    white-space: normal;
  }

}
