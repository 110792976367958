@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.mobile-sticky-donate-bar {
  .ui.button {
    margin-top: calc(-1 * (@inputVerticalPadding + @medium / 2));
  }
}
