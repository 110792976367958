@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-campaign-card {
  border-radius: 0.25rem !important;
  color: black !important;
  overflow: hidden;
  border: 1px solid #EBEBEB !important;

  &:hover,
  &:focus {
    text-decoration: none;

    .me-card-headline {
      text-decoration: underline;
    }
  }

  .content {
    padding: 0.5rem 1rem !important;
  }

  .me-card-headline {
    line-height: 1.5rem;
    height: 4.5rem;
    font-weight: 600;
  }
}
