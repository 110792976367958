@type: 'element';
@element: 'container';

@import (multiple) '../../theme.config';

.me-category {
  margin-top: ~'calc(-1rem - 2px)';

  .search-ranking-modal {
    grid-column: 3;
    grid-row: 3;
    text-align: left;

    @media only screen and (max-width: 767px) {
      grid-row: 4;
      grid-column: 2;
    }
  }
  .me-category--image {
    position: relative;
    display: flex;
    margin: 0 auto;

    @media only screen and (max-width: 767px) {
      height: 20rem;
    }

    @media only screen and (min-width: 768px) {
      height: 24rem;
    }

    h1 {
      position: absolute;
      left: 2rem;
      right: 2rem;
      z-index: 10;
      color: white;
      text-align: center;
      word-wrap: normal;
      -webkit-hyphens: auto;
      hyphens: auto;

      @media only screen and (max-width: 767px) {
        top: 7rem;
        font-size: 2.5rem !important;
        line-height: 3rem !important;
      }

      @media only screen and (min-width: 768px) {
        font-size: 3.5rem !important;
        line-height: 3.5rem !important;
        top: ~'calc(50% - 1.75rem)';
      }
    }

    img {
      flex-shrink: 0;
      object-fit: cover;
      z-index: 5;
      width: 100%;

      @media only screen and (min-width: 768px) {
        clip-path: ellipse(100vw 100% at 50% 0%);
        -webkit-clip-path: ellipse(100vw 100% at 50% 0%);
      }

      @media only screen and (max-width: 767px) {
        clip-path: ellipse(130vw 100% at 50% 0%);
        -webkit-clip-path: ellipse(130vw 100% at 50% 0%);
      }
    }
  }

  .me-category--call-to-action {
    padding: 3rem 0;
    margin-top: 2rem;
    text-align: center;

    p {
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .me-category--filter {
    display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    margin-top: 2rem;
    grid-template-rows: auto;
    align-items: center;
    margin-bottom: 2rem;

    @media only screen and (min-width: @tabletBreakpoint) {
      grid-template-columns: auto 2rem auto;

      .categories-count {
        grid-column: 1;
        grid-row: 1;
        text-align: right;
        margin: 0;
        margin-top: -4px;
      }
      .dropdown-label {
        grid-column: 2;
        grid-row: 1;
      }
      .dropdown {
        grid-column: 3;
        grid-row: 1;
      }
      .input-label {
        grid-column: 2;
        grid-row: 2;
      }
      .input {
        grid-column: 3;
        grid-row: 2;
      }
      .categories-count-addon {
        display: none;
      }
      .count {
        width: 3.6rem;
        display: inline-block;
        text-align: right;
      }
      .dropdown,
      .input {
        width: 16rem;
      }
    }

    @media only screen and (max-width: calc(@tabletBreakpoint - 1px)) {
      grid-template-columns: 2rem auto;

      .categories-count {
        grid-column: 1;
        grid-row: 1;
        margin: 0;
        white-space: nowrap;
      }
      .dropdown-label {
        grid-column: 1;
        grid-row: 2;
      }
      .dropdown {
        grid-column: 2;
        grid-row: 2;
      }
      .input-label {
        grid-column: 1;
        grid-row: 3;
      }
      .input {
        grid-column: 2;
        grid-row: 3;
      }
      .dropdown,
      .input {
        width: 100%;
        min-width: 0;
      }
    }
  }
}
