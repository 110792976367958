@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-postdonation-progress {
  .item {
    flex: 1;
  }

  .right-item {
    text-align: right;
  }

  .center-item {
    display: flex;
    justify-content: center;

    .progress-circle {
      display: flex;
    }
  }

  .left-item,
  .right-item {
    transform: translateY(1.8rem);
  }
}
