.container {
  background: rgb(77, 26, 67);
  color: white;
  display: flex;
  font-size: 13px;
  line-height: 1em;
  list-style-type: none;
  padding: 2px !important; /* override `.me-footer > ul` style */
  z-index: 99;

  li {
    padding: 3px;
  }

  a {
    color: white !important; /* override global `a` styles */
    text-decoration: underline;
  }
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;

  li {
    padding: 3px 6px;
  }

  /* show separator between items */
  li + li {
    border-left: solid 1px #ffffff66;
  }
}

.right {
  position: fixed;
  bottom: 60px;
  right: 0;
  flex-direction: column;
}

.closer {
  cursor: pointer;
}
