.cookieBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100vw;
  height: auto;
  padding: 1em 5em 0.5em 5em;
  border: 7px solid #522ab7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-color: white;

  @media only screen and (max-width: 767px) {
    padding: 0.5em;
  }
}

.row {
  max-width: 1440px;
  padding: 0 10px 0 10px;
}

.logoContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 135px;
}

.title {
  margin-top: 22px !important;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 15px 0;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.linkWrapper {
  display: flex;
  margin-bottom: 15px;
  align-self: center;

  a {
    /* color: var(--darkPrimaryColor); */
    color: #522ab7;

    &:hover {
      color: var(--primaryColor);
    }
  }
}

.buttonWrapper {
  display: flex;

  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }

  @media only screen and (min-width: 768px) {
    flex-basis: 70%;
  }

  @media only screen and (min-width: 990px) {
    flex-basis: 50%;
  }

  :global(.button.ui.button) {
    flex-basis: 50%;
    margin-bottom: 15px;

    @media only screen and (max-width: 767px) {
      flex-basis: 100%;
    }
  }
}

.rejectButton {
  border: 1px solid var(--primaryColor);
  color: var(--darkPrimaryColor);

  @media only screen and (min-width: 768px) {
    margin-right: 12px;
  }

  &:hover {
    border: 1px solid var(--darkPrimaryColor);
    background-color: var(--darkPrimaryColor);
    color: white;
  }
}

.text {
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.modal {
  margin-top: 0;
}

.modalTrigger {
  flex-grow: 1;
}

.cookieBannerLink {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
}
