@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-header.ui {
  border-width: 0 0 1px 0;
  flex-shrink: 0;
  margin-bottom: 0;

  .me-header--logo {
    flex-shrink: 1;
    min-width: 50px;

    img {
      object-fit: cover;
      object-position: left center;

      @media only screen and (max-width: @largestMobileScreen) {
        height: 26.2px;
      }
    }
  }
  .me-header--search {
    background: white;
    padding: 0.25rem 0;

    @media only screen and (min-width: @tabletBreakpoint) {
      width: 25rem;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      position: absolute;
      left: 2.3rem;
      top: 0.1rem;
      width: ~"calc(100vw - 7rem)";
      flex-shrink: 1;
      border-left: 0.5rem solid white;
    }

    form {
      width: 100%;
    }
  }
  .me-header--search-input {
    height: 40px;
  }
  .me-header--dropdown {
    flex-shrink: 0;
  }
  .me-header--dropdown-menu {
    width: 25rem;
    max-width: 100vw;
    margin-right: -1px;
  }
  .me-header--dropdown-button {
    @media only screen and (max-width: @largestMobileScreen) {
      flex-shrink: 0 !important;
    }
  }
}
