@primaryColor: #764FD7;
@legiblePrimaryColor: #764FD7;
@lightPrimaryColor: #FCCCB6;
@darkPrimaryColor: #E0AE00;
@secondaryColor: #291501;
@lightSecondaryColor: #FFD3D2;
@adminColor: #522AB7;
@lightAdminColor: #F2EEFB;
@borderColor: #C4C4C4;
@inputBackground: white;
@adminHintBackgroundColor: #F6CE46;
@buttonPrimaryShadowColor: #4E27AF;
@buttonPrimaryTextColor: white;
@buttonPrimaryHoveredColor: #4E27AF;
@buttonPrimaryHoveredTextColor: white;
@buttonPrimaryFocusBorderColor: #764FD7;
@buttonPrimaryActiveColor: #764FD7;
@buttonDisabledBackgroundColor: #CCCCCC;
@buttonDisabledTextColor: #636363;
@buttonPrimaryBasicBackgroundColor: white;
@buttonPrimaryBasicBorderColor: #764FD7;
@buttonPrimaryBasicTextColor: #764FD7;
@buttonPrimaryBasicShadowColor: #764FD7;
@buttonPrimaryBasicActiveBackgroundColor: #764FD7;
@buttonPrimaryBasicActiveTextColor: #fff;
@buttonDisabledBasicBackgroundColor: #EEEEEE;
@buttonNegativeBackgroundColor: #d32b43;
@buttonNegativeShadowColor: #931C38;
@buttonNegativeTextColor: white;
@buttonNegativeHoveredColor: #b11b3e;
@buttonNegativeBorderColor: #931C38;
@buttonNegativeActiveBackgroundColor: #b11b3e;
@ctaBoxBackgroundColor: #AECFD1;
@ctaBoxTextColor: black;
@progressColor: #E0AE00;
@progressBackgroundColor: #AECFD1;
@hintColor: #636363;
@pricingCellBackgroundColor: #FFEFDB;
@singleColumnBackdropColor: #FFEDAD;
@orgaImprintBackgroundColor: #f8f8f8;
@darkGrayTextColor: #282828;
@lightGrayTextColor: #636363;
@flavor: me;