/*-------------------
        checkbox
--------------------*/

html .ui.checkbox:not(.radio) label::before { top: 4px;}

html .ui.checkbox:not(.radio) {
  line-height: 1.6em;
}

/* Width auto doesn't allow two fields next to each other */

html .ui.form .field .ui.input input,
html .ui.form .fields .field .ui.input input {
  width: 100%;
}
