@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-campaign-progress {

  .progress-circle {
    margin-top: -2.9rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    background: #fff;
    padding: 0.2rem;
    width: 7.5rem;
    height: 7.5rem;

    @media only screen and (max-width: @largestTabletScreen) {
      height: 6rem;
      width: 6rem;
    }
  }

  .item {
    flex: 1;
  }

  .right-item {
    text-align: right;
  }

  .center-item {
    display: flex;
    justify-content: center;
  }
}
