@type    : 'element';
@element : 'container';

@import (multiple) '../../theme.config';

.me-pricing {

  h2.ui.header {
    color: @secondaryColor;
    margin: calc(2rem - 0.15625em ) 0em 1rem
  }

  .grey-text-color {
    color: @greyTextColor
  }

  .is-sticky {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }

  .accordion.ui .title {
    color: @legiblePrimaryColor;
    i {
      color: @black;
    }
  }

  .ui[class*="very basic"].table:not(.sortable):not(.striped) thead tr:first-child th {
    padding: 1.45em 0.55em;
    img {
      margin: 0 auto;
    }
  }

  .me-comparison {
    overflow-x: auto;
    margin-right: -1.1rem;
    margin-left: -1.1rem;
    padding-left: 2.1rem;
    -webkit-overflow-scrolling: touch;

    table {
      width: 768px;
      margin: 3em 0;

      i.check {
        color: @progressColor;
      }

      i.minus {
        color: @lightGrey;
      }

      .me-table-cell {
        background: @pricingCellBackgroundColor;
      }

      .me-table-cell-header,
      .me-table-cell-footer {
        background: @primaryColor;
      }

      .me-table-cell-header {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px
      }

      .me-table-cell-footer {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px
      }

      tbody {
        tr td:last-of-type {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
        tr:last-child td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
